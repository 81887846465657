<template>
  <div>
    <!--  Page Header  -->
    <div class="page-header d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'resource_center' }">
          <button class="btn btn-control">
            <img src="@/assets/icons/bold-icon previous.svg" alt="Return back">
          </button>
        </router-link>
        <h3 class="page-header-title ms-2">Home Buyer Guide</h3>
        <span class="subtitle d-none d-md-inline">Preview</span>
      </div>
      <div class="d-flex">
        <base-button title="Download" action="primary" @click-btn="download('D')" :disabled="ui.download" :loading="ui.isDownload" />
      </div>
    </div>
    <div class="page-content">
      <div class="row w-100 m-auto" v-if="!ui.loading">
        <div class="col-md-8 open-house-col border-right-light">
          <div class="select-page row">
            <div class="col-6 col-lg-1 select-page--title my-auto">
              Pages
            </div>
            <div class="col-10 d-none d-lg-flex justify-content-center my-auto">
              <div v-for="i in numPages" @click="selectPage(i)" :key="i" class="select-page--item"
                   :class="{active: i === selectedPage}">
                {{ i }}
              </div>
            </div>
            <div class="col-6 col-lg-1 d-flex my-auto justify-content-end">
              <div class="d-flex">
                <button class="btn btn-control" :class="{active: selectedPage > 1}" @click="changePdfPage(false)">
                  <img src="@/assets/icons/bold-icon previous.svg" alt="">
                </button>
                <button class="btn btn-control" :class="{active: selectedPage < numPages}" @click="changePdfPage(true)">
                  <img src="@/assets/icons/bold-icon next.svg" alt="">
                </button>
              </div>
            </div>
          </div>
          <pdf :src="src" :page="selectedPage" class="page-preview" :class="{active: ui.preloadPreview === false}"/>
          <page-spinner v-if="ui.preloadPreview"/>
        </div>
        <div class="col-md-4 p-0">
          <div class="open-house-col">
            <div class="col--title">
              Contact Details
            </div>
            <div class="agent-avatar">
              <img :src="agentPhoto(formData.photo)" alt="">
            </div>
            <div class="col-12 mt-3">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group full-width">
                    <label for="fieldName">Name</label>
                    <input type="text" id="fieldName" @change="updatePreview" class="form-control"
                           v-model="formData.name">
                  </div>
                </div>
                <div class="col-md-6 mt-3 mt-sm-0">
                  <div class="form-group full-width">
                    <label for="fieldPosition">Position</label>
                    <input type="text" id="fieldPosition" @change="updatePreview" class="form-control"
                           v-model="formData.position">
                  </div>
                </div>
                <div class="col-12">
                  <div class="col-4 mt-3">
                    <div class="form-group full-width">
                      <label for="fieldNmls">NMLS</label>
                      <input type="text" id="fieldNmls" @change="updatePreview" class="form-control"
                             v-model="formData.nmls">
                    </div>
                  </div>
                </div>
                <div class="col-6 mt-3">
                  <div class="form-group full-width">
                    <label for="fieldPhone">Phone Number</label>
                    <input type="text" id="fieldPhone" @change="updatePreview" class="form-control"
                           v-mask="'(###) ###-####'" v-model="formData.phone">
                  </div>
                </div>
                <div class="col-12 mt-3 ">
                  <div class="form-group full-width">
                    <label for="fieldEmail">Email Address</label>
                    <input type="email" id="fieldEmail" @change="updatePreview" class="form-control"
                           v-model="formData.email">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 border-top">
            <div class="open-house-col">
              <div class="col--title">
                Client Details
              </div>
              <div class="col-12 mt-3">
                <div class="form-group full-width">
                  <label for="fieldClientName">Name</label>
                  <input type="text" id="fieldClientName" @change="updatePreview" class="form-control"
                         v-model="formData.clientName">
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="form-group full-width">
                  <label for="fieldAddress">Address</label>
                  <input type="email" id="fieldAddress" @change="updatePreview" class="form-control"
                         v-model="formData.address">
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="form-check p-0">
                  <input class="form-check-input p-0 m-0" v-model="formData.hideAddressSection" @change="updatePreview" type="checkbox" value="" id="hideAddressSection">
                  <label class="form-check-label" for="hideAddressSection">
                    Hide client section
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-5" v-else>
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <transition mode="out-in" name="component-fade">
      <my-upload v-model="show" :height="300" :noSquare="true" :params="params" :width="300" field="img"
                 img-format="png" lang-type="en" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess"/>
    </transition>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import BaseButton from '../../components/BaseButton.vue';
import PageSpinner from "../../components/pageSpinner";

export default {
  name: "HomebuyerGuide",
  components: {
    pdf,
    PageSpinner,
    BaseButton
  },
  data() {
    return {
      ui: {
        loading: true,
        download: false,
        edit: false,
        preloadPreview: false,
        isDownload: false
      },
      baseUrl: process.env.VUE_APP_API_URL + '/api/v1/homebuyer-guide/download',
      src: null,
      numPages: 0,
      formData: {
        name: '',
        position: '',
        nmls: '',
        phone: '',
        email: '',
        photo: '',
        address: 'TBD',
        hideAddressSection: false,
        clientName: ''
      },
      selectedPage: 1,
      show: false,
      customPhoto: false,
      params: {
        token: '123456798',
        name: 'avatar'
      },
    }
  },
  methods: {
    download(dest = 'D') {
      this.ui.download = true
      this.ui.isDownload = true
      this.$http.get('/api/v1/homebuyer-guide/download', {
        params: {
          dest: dest,
          name: this.formData.name,
          position: this.formData.position,
          nmls: this.formData.nmls,
          phone: this.formData.phone,
          email: this.formData.email,
          address: this.formData.address,
          isDownload: 1,
          hideAddressSection: 1,
          clientName: this.formData.clientName
        },
        responseType: 'arraybuffer'
      })
        .then((res) => {
          let fileURL = window.URL.createObjectURL(new Blob([res.data], {
            type: 'application/pdf'
          }))
          let fileLink = document.createElement('a');

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'homebuyer-guide.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(() => {
        })
        .finally(() => {
          this.ui.download = false
          this.ui.isDownload = false
        })
    },
    getInfo(val) {
      if (val) {
        this.ui.edit = false
        this.formData = val
        // Don't refactor this line, data can be shifted in pdf
        this.src = pdf.createLoadingTask(this.baseUrl + `?id=${this.$store.getters.getUserId}&name=${this.formData.name}&position=${this.formData.position}&nmls${this.formData.nmls}&phone=${this.formData.phone}&email=${this.formData.email}&address=${this.formData.address}&hideAddressSection=${this.formData.hideAddressSection}&clientName=${this.formData.clientName}`)

        this.src.promise.then(pdf => {
          this.ui.loading = false
          this.numPages = pdf.numPages
        })
      } else {
        this.ui.edit = false
      }
    },
    selectPage(val) {
      this.selectedPage = val
    },
    changePdfPage(val) {
      if (!val) {
        this.selectedPage--
      } else {
        this.selectedPage++
      }
    },
    cropSuccess(imgDataUrl) {
      this.formData.photo = imgDataUrl;
      this.customPhoto = true
    },
    cropUploadSuccess() {
      this.show = false;
    },
    updatePreview() {
      this.ui.preloadPreview = true

      this.src = pdf.createLoadingTask(this.baseUrl + `?id=${this.$store.getters.getUserId}&name=${this.formData.name}&position=${this.formData.position}&nmls=${this.formData.nmls}&phone=${this.formData.phone}&email=${this.formData.email}&address=${this.formData.address}&hideAddressSection=${this.formData.hideAddressSection}&clientName=${this.formData.clientName}`)
      this.src.promise.then(pdf => {
        this.ui.loading = false
        this.numPages = pdf.numPages
        this.ui.preloadPreview = false
      })
    }
  },
  mounted() {
    this.formData.name = this.$store.getters.getFullName
    this.formData.position = this.$store.getters.getPosition
    this.formData.nmls = this.$store.getters.getNmls
    this.formData.phone = this.$store.getters.getPhone
    this.formData.photo = this.$store.getters.getPhoto
    this.formData.email = this.$store.getters.getEmail

    this.src = pdf.createLoadingTask(this.baseUrl + `?id=${this.$store.getters.getUserId}&name=${this.formData.name}&position=${this.formData.position}&nmls${this.formData.nmls}&phone=${this.formData.phone}&email=${this.formData.email}&address=${this.formData.address}&hideAddressSection=${this.formData.hideAddressSection}&clientName=${this.formData.clientName}`)
    this.src.promise.then(pdf => {
      this.ui.loading = false
      this.numPages = pdf.numPages
    })
  }
}
</script>

<style lang="scss" scoped>
.page-header {
  button {
    &.btn-primary {
      min-width: 120px;
    }
  }
}

.page-content {
  padding: 0;
}

.page-preview {
  display: none !important;
  width: 100%;
  border: #CCCCCC 1px solid;
  border-width: 0 1px 1px 1px;
  margin-bottom: 30px;

  &.active {
    display: inline-block !important;
  }
}

.col--title {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}

.agent-avatar {
  margin-top: 24px;

  img {
    height: 96px;
    width: 96px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.form-group {
  label {
    color: rgba(0, 0, 0, .7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
  }

  input {
    height: 36px;
    border-radius: 4px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
}

.select-page {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #CCCCCC;
  border-radius: 4px 4px 0 0;
  padding: 10px 18px;

  &--title {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }

  &--item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    color: rgba(0, 0, 0, .7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin: 0 5px;
    cursor: pointer;
    transition: all .3s;
    border: 2px solid #fff;

    @media (max-width: 768px) {
      display: none;
    }

    &:hover {
      color: rgba(0, 0, 0, 1);
      border: 2px solid #ececec;
      border-radius: 4px;
    }

    &.active {
      border: 2px solid #065AE4;
      border-radius: 4px;
      color: #065AE4;
    }
  }

  button {
    cursor: not-allowed;
    pointer-events: none;

    &.active {
      cursor: pointer;
      pointer-events: initial;

      img {
        opacity: 1;
      }
    }
  }
}

.open-house-col {
  padding: 24px;
}

.form-check-input {
  width: 18px !important;
  height: 18px !important;
}
</style>
